export const environment = {
  envName: 'prod',
  production: true,
  apiUrl: 'https://api.ripe.capital/',
  tokenUrl: 'token',
  externalUrls : {
    "TgCommunityLink": 'https://t.me/ripecapital',
  },
  botName: 'RipeCapitalBot',
  botId: '6592961966',
  buildAdmin: false,
  enableGoogleAnalytics: true,
  enableFacebookAnalytics: true,
  rangoApiKey: '06dd91cf-6c2e-4a70-bef9-6d2a512f7d62', 
  walletConnectProjectId: '48a72061c061c3e7c000bfcd7e55901e'
};

export const blockexplorers: {[key: string]: string} = {
  bscscan : "https://bscscan.com/" ,
  etherscan: "https://etherscan.io/" 
};